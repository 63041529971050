<template>
  <v-container>
    <v-tabs
      v-model="tabs"
      :vertical="!$vuetify.breakpoint.smAndDown"
      :class="`${$vuetify.breakpoint.smAndDown ? 'px-1' : ''}`"
      color="accentSuite"
      show-arrows
    >
      <div v-show="!vacation">
        <v-tab
          v-for="tab in tabsName"
          :key="tab.id"
          active-class="accent white--text"
          :class="`${
            $vuetify.breakpoint.smAndDown ? 'ml-2' : 'd-flex justify-end '
          } text-none text-body-1 font-weight-medium ${
            $vuetify.breakpoint.smAndDown ? 'rounded-t-lg' : ''
          }`"
          :disabled="loadingSign"
          :href="`#${tab.id}`"
        >
          {{ tab.name }}
        </v-tab>
      </div>

      <!-- All templates -->
      <v-tab-item
        id="1"
        :class="`${$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 px-6 pt-0'}`"
      >
        <v-card
          :tile="$vuetify.breakpoint.mdAndUp"
          :outlined="$vuetify.breakpoint.smAndDown"
          elevation="0"
          :class="`${
            $vuetify.breakpoint.smAndDown ? 'px-4' : ''
          } overflow-y-auto ${!hasConectaSign ? 'no-scroll' : ''}`"
        >
          <v-card-text
            :class="`${
              $vuetify.breakpoint.smAndDown ? 'px-0 mt-6' : ''
            } pa-0 mt-0`"
          >
            <div class="d-flex">
              <v-text-field
                filled
                class="elevation-0"
                prepend-inner-icon="ph-magnifying-glass"
                :label="$t('vacationApply.model.search')"
                v-model="search"
                color="accent"
                :disabled="loadingSign"
                flat
                full-width
                :loading="loadingSign"
              />
              <v-btn
                color="accent"
                elevation="0"
                class="ml-4 text-none text-body-1 font-weight-medium"
                dark
                large
                height="55px"
                :fab="$vuetify.breakpoint.xs"
                @click="tabs = '2'"
              >
                <v-icon
                  :left="$vuetify.breakpoint.smAndUp"
                  v-text="'ph-pencil-simple'"
                />
                <span v-if="$vuetify.breakpoint.smAndUp">{{
                  $t("action.writeMessage")
                }}</span>
              </v-btn>
            </div>
            <!-- Filtro de tipo de modelos (Mobile) -->
            <v-select
              v-if="$vuetify.breakpoint.smAndDown"
              v-model="currentFilter"
              :items="templateType"
              :label="$t('vacationApply.model.filter')"
              prepend-inner-icon="ph-fill ph-funnel"
              item-text="name"
              item-value="value"
              filled
              flat
              full-width
            ></v-select>
          </v-card-text>

          <div>
            <!-- LISTA SELECIONÁVEL DE MODELOS (Mobile) -->
            <v-item-group
              v-if="$vuetify.breakpoint.smAndDown"
              v-model="selectedTemplateKey"
            >
              <v-row>
                <v-col
                  v-for="(item, i) in mobileTemplatesView"
                  :key="i"
                  cols="12"
                  sm="6"
                  xs="12"
                >
                  <v-item v-slot="{ active, toggle }" :value="item[itemValue]">
                    <v-card
                      hover
                      outlined
                      height="225"
                      :class="`${active ? 'selected-border' : ''} py-2`"
                      @click.stop="toggle(item[itemValue])"
                    >
                      <v-scroll-y-transition>
                        <v-list-item :ripple="false" class="ma-0 pa-0">
                          <v-list-item-content
                            :ripple="false"
                            class="ma-0 pa-0"
                          >
                            <CardTemplate
                              :custom="!item.is_default"
                              flat
                              custom-click
                              custom-class="pa-0 ma-0 mb-2"
                              read-only
                              :info="item"
                              :is-vacation-template="vacation"
                              no-actions
                            />
                          </v-list-item-content>
                          <v-list-item-action class="pr-3">
                            <v-icon
                              @click.stop="toggle(item[itemValue])"
                              v-text="
                                active ? 'ph-fill ph-check-circle' : 'ph-circle'
                              "
                              color="primary"
                              class="mx-1"
                              x-large
                            />
                          </v-list-item-action>
                        </v-list-item>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
              <!-- Texto de modelo não encontrado -->
              <v-card-subtitle
                class="text-center mb-4"
                v-if="search && mobileTemplatesView.length === 0"
              >
                {{ $t("vacationApply.model.noModelFor", { search }) }}
              </v-card-subtitle>
              <!-- Botões de ação (Carregar mais e Avançar) -->
              <v-row class="my-8 px-3">
                <v-btn
                  @click="next()"
                  :dark="hasSelecedTemplateKey"
                  color="accent"
                  block
                  x-large
                  :disabled="!selectedTemplateKey"
                >
                  {{ $t("common.advance") }}
                </v-btn>
              </v-row>
            </v-item-group>

            <div v-else>
              <div :class="`${!hasConectaSign ? 'overlay-container' : ''}`">
                <v-card-subtitle class="mt-0 pt-0 mb-0 font-weight-medium">
                  {{ $t("applySignature.customModels") }}
                </v-card-subtitle>
                <v-card-actions class="pt-0 mt-0 px-0">
                  <v-row class="ma-0 pa-0">
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="4"
                      xl="3"
                      v-for="(item, i) in allCustomTemplates"
                      :key="i"
                    >
                      <CardTemplate
                        :info="item"
                        custom
                        :is-vacation-template="vacation"
                        @selectCard="selectTemplate"
                        no-actions
                      />
                    </v-col>
                  </v-row>
                </v-card-actions>

                <v-card-subtitle
                  class="mt-0 pt-0 mb-4"
                  v-if="search && allCustomTemplates.length === 0"
                >
                  {{ $t("vacationApply.model.noCustomModelFor", { search }) }}
                </v-card-subtitle>

                <v-card-subtitle class="mt-6 pt-0 mb-0 font-weight-medium">
                  {{ $t("applySignature.defaultModels") }}
                </v-card-subtitle>
                <v-card-actions class="pt-0 mt-0 px-0">
                  <v-row class="ma-0 pa-0">
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="4"
                      xl="3"
                      v-for="(item, i) in allDefaultTemplates"
                      :key="i"
                    >
                      <CardTemplate
                        :info="item"
                        :is-vacation-template="vacation"
                        @selectCard="selectTemplate"
                        no-actions
                      />
                    </v-col>
                  </v-row>
                </v-card-actions>

                <v-card-subtitle
                  class="mt-0 pt-0"
                  v-if="search && allDefaultTemplates.length === 0"
                >
                  {{ $t("vacationApply.model.noDefaultModelFor", { search }) }}
                </v-card-subtitle>

                <VacationOverlayUpsell
                  v-if="!hasConectaSign"
                  subtitle-key="userInformations.upsellState.vacation.upgrade"
                  state-icon-key="ph-rocket-launch"
                  icon-color="#5B36C3"
                  @click:action="goToSelfCheckout"
                />
              </div>
            </div>
          </div>
        </v-card>
      </v-tab-item>

      <!-- New vacation template -->
      <v-tab-item id="2" :class="`py-0 pt-0`">
        <v-card
          :flat="$vuetify.breakpoint.mdAndUp"
          :outlined="$vuetify.breakpoint.smAndDown"
          :class="`${
            $vuetify.breakpoint.smAndDown ? 'px-4 py-6' : 'py-0 px-6'
          }`"
        >
          <VacationSettingsForm
            @html="setTemplate"
            @newTemplateId="selectTemplate"
            new-template
            @cancel="tabs = '1'"
          />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>
<script>
import VacationSettingsForm from "@/components/main/shared/VacationSettingsForm.vue";
import CardTemplate from "@/components/sign/general/CardTemplate";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import { getAnalytics, logEvent } from "firebase/analytics";
import { mapActions, mapGetters } from "vuex";
import VacationOverlayUpsell from "../vacation/VacationOverlayUpsell.vue";

export default {
  name: "TemplateSelector",

  props: {
    show: { type: Boolean, required: true },
    vacation: { type: Boolean, default: false },
    signature: { type: Boolean, default: false },
    customItems: { type: [Array, Boolean], default: false },
    itemText: { type: String, default: "name" },
    hideSearch: { type: Boolean, default: false },
    title: { type: String, default: "" },
  },

  components: { CardTemplate, VacationSettingsForm, VacationOverlayUpsell },

  data() {
    return {
      search: "",
      tabs: "#1",
      errorMessages,
      selectedTemplate: null,
      selectedTemplateKey: null,
      filter: null,
    };
  },

  computed: {
    ...mapGetters([
      "allVacationTemplates",
      "defaultVacationTemplates",
      "companyVacationTemplates",
      "allTemplates",
      "customTemplates",
      "defaultTemplates",
      "primaryColor",
      "secondaryColor",
      "loadingSign",
      "hasConectaSign",
      "mainDomain",
      "currentUser",
    ]),

    hasSelecedTemplateKey() {
      return this.selectedTemplateKey ? true : false;
    },

    tabsName() {
      return [
        {
          name: this.$vuetify.breakpoint.smAndDown
            ? this.$t("common.models")
            : this.$t("vacationApply.model.allModels"),
          id: 1,
          hidden: false,
        },
        {
          name: this.$t("applySignature.customModels"),
          id: 2,
          hidden: this.$vuetify.breakpoint.smAndDown,
        },
        {
          name: this.$t("applySignature.defaultModels"),
          id: 3,
          hidden: this.$vuetify.breakpoint.smAndDown,
        },
      ].filter((tab) => !tab.hidden);
    },

    templateType() {
      return [
        {
          name: this.$t("vacationApply.model.allModels"),
          value: "all",
        },
        {
          name: this.$t("applySignature.customModels"),
          value: "custom",
        },
        {
          name: this.$t("applySignature.defaultModels"),
          value: "default",
        },
      ];
    },

    isCustom() {
      return this.customItems;
    },
    itemValue() {
      return this.signature || this.customItems ? "id" : "key";
    },
    templates() {
      if (this.signature) {
        return this.allTemplates;
      } else if (this.vacation) {
        return this.allVacationTemplates;
      }
      return this.customItems;
    },

    filteredTemplates() {
      return this.search && this.hasConectaSign
        ? this.templates.filter((template) =>
            template.name.toUpperCase().match(this.search.toUpperCase())
          )
        : this.templates;
    },

    allCustomTemplates() {
      return this.search && this.hasConectaSign
        ? this.companyVacationTemplates.filter((template) =>
            template.name.toUpperCase().match(this.search.toUpperCase())
          )
        : this.companyVacationTemplates;
    },

    allDefaultTemplates() {
      if (this.vacation) {
        return this.search != ""
          ? this.defaultVacationTemplates.filter((template) =>
              template.name.toUpperCase().match(this.search.toUpperCase())
            )
          : this.defaultVacationTemplates;
      } else if (this.signature) {
        return this.search != ""
          ? this.defaultTemplates.filter((template) =>
              template.name.toUpperCase().match(this.search.toUpperCase())
            )
          : this.defaultTemplates;
      }
      return this.customItems;
    },

    currentFilter: {
      get() {
        return this.filter;
      },
      set(new_value) {
        this.filter = new_value;
      },
    },

    mobileTemplatesView() {
      if (this.currentFilter == "default") {
        return this.allDefaultTemplates;
      } else if (this.currentFilter == "custom") {
        return this.allCustomTemplates;
      }
      return this.filteredTemplates;
    },
  },

  watch: {
    show() {
      if (this.show) {
        this.reset();
      }
    },
    selectedTemplateKey() {
      if (this.selectedTemplateKey) {
        this.getTemplate(this.selectedTemplateKey);
      }
    },
  },

  methods: {
    ...mapActions([
      "getVacationTemplates",
      "getCustomTemplates",
      "getCustomTemplates",
    ]),

    goToSelfCheckout() {
      const analytics = getAnalytics();
      logEvent(analytics, "go_to_self_checkout", {
        click: "modelos de assinatura no fluxo de férias",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description: `Clicou para conhecer planos na escolha de modelos`,
      });
      this.$router.push("/billing/upgrade");
    },

    getTemplate(id) {
      this.selectedTemplate = this.templates.find(
        (template) => template.id === id || template.key === id
      );
    },

    selectTemplate(id) {
      this.getTemplate(id);
      this.next();
    },

    next() {
      if (this.selectedTemplate) {
        this.$emit("clickedTemplate", this.selectedTemplate);
        this.reset();
      }
    },

    setTemplate(template) {
      this.$emit("html", template);
      this.reset();
    },

    reset() {
      this.currentFilter = null;
      this.tabs = "1";
      this.search = "";
    },
  },

  async beforeMount() {
    this.reset();

    await this.getVacationTemplates();
  },
};
</script>

<style scoped>
.overlay-container {
  position: relative;
  width: 100%;
  height: 388px;
}

.no-scroll {
  overflow: hidden !important; /* Hides overflow and removes scrollbars */
  overflow-x: hidden !important; /* No horizontal scroll */
  overflow-y: hidden !important; /* No vertical scroll */
}
</style>
