<template>
  <v-container style="max-width: 1160px">
    <v-form class="pb-6 overflow-x-hidden">
      <v-card-title v-if="$vuetify.breakpoint.mdAndUp" class="px-0 pt-0">
        {{ $t("vacationApply.configure.pageTitle") }}
      </v-card-title>
      <v-card-text
        v-if="$vuetify.breakpoint.mdAndUp"
        class="px-0 text-body-1 mb-4"
      >
        {{ $t("vacationApply.configure.description") }}
      </v-card-text>

      <v-card outlined class="mb-6 pb-2">
        <v-card-subtitle class="font-weight-medium">
          {{ $t("vacationApply.configure.vacationPeriod") }}
        </v-card-subtitle>
        <v-divider />
        <v-row
          :align="$vuetify.breakpoint.smAndDown ? 'start' : 'center'"
          class="px-4 py-2"
        >
          <v-col
            v-if="!$vuetify.breakpoint.xs"
            cols="12"
            sm="12"
            lg="3"
            class="text-body-1 font-weight-medium"
          >
            {{ $t("common.startEndDate") }}
            <span class="error--text font-weight-medium">*</span>
          </v-col>
          <v-col cols="12" sm="12" lg="9" class="pt-6">
            <v-menu
              v-model="menuVacationPeriod"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              min-width="auto"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  append-icon="ph-calendar-blank"
                  v-model="vacationPeriod"
                  :label="$t('common.selectStartEndDate')"
                  :placeholder="vacationPeriodLabel"
                  readonly
                  hide-details
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  color="accentSuite"
                />
              </template>
              <v-date-picker
                v-model="vacationDates"
                :min="todayDataBaseFormat"
                range
                color="accentSuite"
              >
                <v-spacer></v-spacer>
                <v-tooltip :disabled="vacationDates.length == 2" top>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        text
                        color="accentSuite"
                        :disabled="vacationDates.length < 2"
                        @click="confirmDatesVacation()"
                        >{{ $t("action.ok") }}</v-btn
                      >
                    </div>
                  </template>
                  <span>{{ $t("common.selectStartEndDate") }}</span>
                </v-tooltip>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card>

      <v-card outlined class="my-6 pb-6">
        <v-card-subtitle class="font-weight-medium">
          {{ $t("vacationApply.configure.restrictions") }}
          <TooltipIcon
            :label="$t('vacationApply.configure.restrictions')"
            icon="ph-question"
            color="accent"
          />
        </v-card-subtitle>
        <v-divider />

        <v-row
          :align="$vuetify.breakpoint.smAndDown ? 'start' : 'center'"
          class="px-4 pt-2"
        >
          <v-col
            v-if="!$vuetify.breakpoint.xs"
            cols="12"
            sm="12"
            lg="3"
            class="text-body-1 font-weight-medium"
          >
            {{ $t("vacationApply.configure.contactsOnly") }}
          </v-col>
          <v-col cols="12" sm="12" lg="9">
            <v-switch
              v-model="updatedSettings.restrict_to_contacts"
              :label="restrictToContactsLabel"
              color="accentSuite"
              style="max-height: 50px"
              inset
              hide-details
            />
          </v-col>
        </v-row>
        <v-row
          :align="$vuetify.breakpoint.smAndDown ? 'start' : 'center'"
          class="px-4 pt-2"
        >
          <v-col
            v-if="!$vuetify.breakpoint.xs"
            cols="12"
            sm="12"
            lg="3"
            class="text-body-1 font-weight-medium"
          >
            {{ $t("vacationApply.configure.companyOnly") }}
          </v-col>
          <v-col cols="12" sm="12" lg="9">
            <v-switch
              v-model="updatedSettings.restrict_to_domain"
              :label="restrictToDomainLabel"
              color="accentSuite"
              inset
              hide-details
            />
          </v-col>
        </v-row>
      </v-card>

      <v-card-actions
        :class="`${$vuetify.breakpoint.smAndDown ? 'mt-4' : ''} px-2`"
      >
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          :disabled="disableNext"
          @click="$emit('vacationSettings', updatedSettings)"
          large
          class="px-6"
          color="accent"
          :dark="!disableNext"
        >
          {{ $t("common.advance") }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>
<script>
import TooltipIcon from "@/components/base/TooltipIcon.vue";
import {
  formatDateWithDayOfWeek,
  getVacationDates,
  todayDataBaseFormat,
} from "@/helpers/services/utils";
import { mapGetters } from "vuex";
export default {
  name: "VacationSettings",

  props: {
    html: { type: String, required: true },
    subject: { type: String, required: true },
    name: { type: String, default: "" },
  },

  components: { TooltipIcon },

  data() {
    return {
      todayDataBaseFormat,
      vacationSettings: {
        enable_auto_reply: true,
        response_subject: this.subject,
        response_body_plain_text: null,
        response_body_html: this.html,
        restrict_to_contacts: false,
        restrict_to_domain: false,
        start_time: null,
        end_time: null,
        calendar_event_name: this.$t("vacationApply.configure.eventName"),
        forwarding_email_on_vacation: false,
      },
      menuVacationPeriod: false,
      vacationDates: [],
    };
  },
  computed: {
    ...mapGetters(["users"]),

    disableNext() {
      return !this.updatedSettings.start_time || !this.updatedSettings.end_time;
    },

    restrictToContactsLabel() {
      if (this.$vuetify.breakpoint.xs) {
        return this.$t("vacationApply.configure.contactsOnly");
      }
      return this.updatedSettings.restrict_to_contacts
        ? this.$t("common.activated")
        : this.$t("common.deactivated");
    },

    restrictToDomainLabel() {
      if (this.$vuetify.breakpoint.xs) {
        return this.$t("vacationApply.configure.companyOnly");
      }
      return this.updatedSettings.restrict_to_domain
        ? this.$t("common.activated")
        : this.$t("common.deactivated");
    },

    vacationPeriodLabel() {
      if (this.$vuetify.breakpoint.xs) {
        return this.$t("common.startEndDate");
      }
      return this.$t("common.selectStartEndDate");
    },

    vacationPeriod() {
      const { start_time, end_time } = this.vacationSettings;
      if (!start_time || !end_time) {
        return null;
      }
      return [start_time, end_time]
        .map((date) => formatDateWithDayOfWeek(date))
        .join(" ~ ");
    },

    updatedSettings: {
      get() {
        return this.vacationSettings;
      },
      set(newValue) {
        this.vacationSettings = newValue;
      },
    },
  },
  methods: {
    confirmDatesVacation() {
      this.menuVacationPeriod = false;
      [this.updatedSettings.start_time, this.updatedSettings.end_time] =
        this.vacationDates.sort();
    },
  },

  beforeMount() {
    const { vacationStart, vacationEnd } = getVacationDates();

    if (vacationStart && vacationEnd) {
      this.vacationDates = [vacationStart, vacationEnd];
      this.confirmDatesVacation();
    }
  },
};
</script>
