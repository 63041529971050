<template>
  <v-container
    fluid
    class="d-flex flex-column pa-0"
    style="align-items: center !important"
  >
    <v-card
      tile
      flat
      class="d-flex flex-column justify-center align-center overflow-auto"
    >
      <v-card-title
        class="primary--text text-h6 text-sm-h5 font-weight-bold text-center break-word text-wrap normal-line-height pa-0"
      >
        {{ $t("vacationApply.concludedVacationFlow.finishFlow") }}
      </v-card-title>

      <v-subheader
        class="text-none text-body-1 font-weight-medium text-center pb-3"
      >
        {{ $t("vacationApply.concludedVacationFlow.finishFlowSubtitle") }}:
      </v-subheader>
      <v-row align="start" class="mb-3 ma-0 pa-0">
        <v-col sm="12" md="6" lg="6">
          <v-card outlined class="pa-5 pt-0">
            <v-card-subtitle class="font-weight-bold pl-0">
              {{ $t("vacationApply.concludedVacationFlow.selectedModel") }}
            </v-card-subtitle>
            <TemplatePreview
              v-if="!!templateModel"
              :title="templateModel?.name || $t('common.vacationMessages')"
              :subject="templateModel?.response_subject"
              :html="templateModel?.content"
              :infoToRender="firstUserSelected"
              show-expand
              hide-bottom
              is-vacation-template
              outlined
            />
          </v-card>
        </v-col>
        <v-col lg="6">
          <v-card outlined class="mb-3 pb-2">
            <v-card-subtitle class="font-weight-bold">
              {{ $t("vacationApply.concludedVacationFlow.selectedPeriod") }}
            </v-card-subtitle>

            <v-text-field
              v-model="vacationPeriod"
              append-icon="ph-calendar-blank"
              readonly
              hide-details
              class="ml-5 mr-5"
              solo
              style="border: 1px solid black"
            />
          </v-card>
          <v-card outlined class="mb-3 pb-2">
            <v-card-subtitle class="font-weight-bold">
              {{ $t("vacationApply.concludedVacationFlow.selectedUsers") }}
            </v-card-subtitle>

            <v-card
              class="mr-5 ml-5 rounded"
              flat
              outlined
              style="border: 1px solid black"
            >
              <div class="pa-2">
                <v-chip
                  color="lighten-3"
                  pill
                  class="ma-1"
                  v-for="(item, index) in selectedUsers"
                  :key="`u${index}`"
                >
                  <v-avatar width="30" left>
                    <img :src="item.photo || '@/assets/user-default.png'" />
                  </v-avatar>
                  <span class="px-2">
                    {{ item.name }}
                  </span>
                </v-chip>
              </div>
            </v-card>
          </v-card>
          <v-card
            outlined
            class="mb-3 pb-2 pr-5 pl-5"
            v-if="settings?.create_calendar_event"
          >
            <v-card-subtitle class="font-weight-bold pl-0">
              {{ $t("vacationApply.concludedVacationFlow.calendarEvent") }}
            </v-card-subtitle>

            <v-text-field
              append-icon="ph-calendar-blank"
              v-model="calendarEventName"
              readonly
              hide-details
              solo
              style="border: 1px solid black"
            />
          </v-card>
          <v-card
            outlined
            class="mb-3 pb-2 pr-5 pl-5"
            v-if="!!settings?.forwarding_email_on_vacation"
          >
            <v-card-subtitle class="font-weight-bold pl-0">
              {{ $t("vacationApply.concludedVacationFlow.forwardingEmail") }}
            </v-card-subtitle>

            <v-text-field
              v-model="forwardingEmail"
              readonly
              hide-details
              solo
              style="border: 1px solid black"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-divider style="width: 90vw"></v-divider>
    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3"
      width="90vw"
    >
      <v-spacer />
      <div>
        <v-btn
          height="52"
          color="accent"
          class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
          @click="goHome"
          :loading="loading"
        >
          <v-icon right v-text="'ph-house'" class="mr-4" size="22" />
          {{ $t("common.homePage") }}
        </v-btn>
      </div>
    </v-footer>
    <!-- MODAL DE FEEDBACK -->
    <HeaderDialog
      ref="form"
      id="vacation_user_feedback"
      :title="$t('common.feedbackRatingTitle')"
      :show="showUserFeedback"
      width="600"
      :persistent="false"
      custom-footer
      @close="closeFeedback"
    >
      <template v-slot:body>
        <v-row class="mx-0">
          <v-col class="mx-0">
            <FeedbackRating
              action="vacation_flow"
              showDismiss
              @close="closeFeedback"
            />
          </v-col>
        </v-row>
      </template>
    </HeaderDialog>
  </v-container>
</template>
<script>
import FeedbackRating from "@/components/forms/FeedbackRating.vue";
import TemplatePreview from "@/components/sign/general/TemplatePreview.vue";
import {
  checkShowFeedback,
  formatDateWithDayOfWeek,
  renewFeedbackOnSecondTime,
  setNotShowFeedbackToday,
} from "@/helpers/services/utils";
import { mapGetters } from "vuex";
export default {
  name: "ConcludedVacationFlow",
  components: { TemplatePreview, FeedbackRating },
  props: {
    selectedUsers: { type: Array, default: () => [] },
    template: { type: Object, default: () => {} },
    customTemplate: { type: Object, default: () => {} },
    settings: { type: Object, default: () => {} },
  },

  data() {
    return {
      loading: false,
      showUserFeedback: false,
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
    templateModel() {
      return this.template
        ? this.template
        : {
            ...this.customTemplate,
            content: this.customTemplate?.response_body_html,
          };
    },
    firstUserSelected() {
      return this.selectedUsers[0] || {};
    },
    vacationPeriod() {
      if (!this.settings?.start_time || !this?.settings.end_time) {
        return "";
      }
      return [this.settings.start_time, this.settings.end_time]
        .map((date) => formatDateWithDayOfWeek(date))
        .join(" ~ ");
    },
    forwardingEmail() {
      return this.settings?.forwarding_email_on_vacation || "";
    },
    calendarEventName() {
      return this.settings.calendar_event_name || "";
    },
  },
  methods: {
    renewFeedbackOnSecondTime,
    setNotShowFeedbackToday,
    checkShowFeedback,
    checkFeedback() {
      this.showUserFeedback = true;
    },
    goHome() {
      if (
        this.checkShowFeedback("vacation_flow") &&
        renewFeedbackOnSecondTime(this.currentUser.feedbacks?.vacation_flow)
      ) {
        this.showUserFeedback = true;
        return;
      }
      this.$router.push({ name: "Home" });
    },
    closeFeedback() {
      this.showUserFeedback = false;
      this.setNotShowFeedbackToday("vacation_flow");
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
<style scoped>
.link-style {
  text-decoration: underline;
  cursor: pointer;
}
</style>
