<template>
  <div>
    <FullscreenStepsDialog
      :show="showHideDialog"
      :title="$t('applySignature.applyVacationMessage')"
      :steps="stepNames"
      :current-step="currentStep"
      :show-divider="false"
      :can-go-back="true"
      :loading="loadingSign"
      @update:current-step="currentStep = $event"
      @close="handleClose"
    >
      <template #stepper-content.1>
        <StartedVacationFlow show @started="currentStep = 2" />
      </template>
      <template #stepper-content.2>
        <TemplateSelector
          show
          vacation
          @html="setTemplate"
          @clickedTemplate="setSelectedTemplate"
        />
      </template>
      <template #stepper-content.3>
        <VacationSettings
          :name="selectedTemplateName"
          :html="htmlTemplate"
          :subject="subjectTemplate"
          @vacationSettings="
            setVacationSettings($event);
            currentStep = 4;
          "
        />
      </template>
      <template #stepper-content.4>
        <AbsenseSettings
          :name="selectedTemplateName"
          :html="htmlTemplate"
          :subject="subjectTemplate"
          @vacationSettings="
            setVacationSettings($event);
            currentStep = 5;
          "
        />
      </template>
      <template #stepper-content.5>
        <!-- TODO: Aviso de que o usuário já está de férias no Control -->
        <v-row v-if="hasUsersWithVacation" class="mx-0 mb-3">
          <Alert
            color="warning"
            class="mt-2"
            text
            dense
            expansion
            action-text="Ver informações"
            action-icon="ph-users"
            style="width: 100%"
          >
            <span>{{ $t("vacationApply.overwriteWarning") }} </span>
            <template #expansion>
              <ul>
                <li
                  v-for="user in selectedUsesWithVacationSettings"
                  :key="user.email"
                >
                  {{ user.email }}
                </li>
              </ul>
            </template>
          </Alert>
        </v-row>
        <ConfirmTemplateApply
          :template-subject="subjectTemplate"
          :templateName="selectedTemplateName"
          :template-html="htmlTemplate"
          :selected-users="autocompleteSelected"
          @change:selected-users="autocompleteSelected = $event"
          @apply="applyVacation"
          @skip="currentStep = 6"
        />
      </template>
      <template #stepper-content.6>
        <ManageUserAccess
          :vacation-settings="settings || {}"
          :selected-users="autocompleteSelected"
          @change:selected-users="autocompleteSelected = $event"
          @input:vacation-settings="settings = $event"
          @skip="currentStep = 7"
        />
      </template>
      <template #stepper-content.7>
        <ConcludedVacationFlow
          @finish="finish"
          :selectedUsers="localSelectedUsers"
          :settings="settingsValues"
          :template="selectedTemplate"
          :customTemplate="customTemplate"
        />
      </template>
    </FullscreenStepsDialog>
  </div>
</template>
<script>
import FullscreenStepsDialog from "@/components/main/shared/FullscreenStepsDialog.vue";
import TemplateSelector from "@/components/sign/general/TemplateSelector.vue";
import AbsenseSettings from "@/components/sign/vacation/AbsenseSettings.vue";
import ConcludedVacationFlow from "@/components/sign/vacation/batch/ConcludedVacationFlow.vue";
import ConfirmTemplateApply from "@/components/sign/vacation/batch/ConfirmTemplateApply.vue";
import ManageUserAccess from "@/components/sign/vacation/batch/ManageUserAccess.vue";
import VacationSettings from "@/components/sign/vacation/batch/VacationSettings.vue";
import StartedVacationFlow from "@/components/vacationFlow/StartedVacationFlow.vue";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "VacationApplyInBatchDialog",

  props: {
    show: { type: Boolean, required: true },
    selectedUsers: { type: Array },
  },

  components: {
    FullscreenStepsDialog,
    TemplateSelector,
    VacationSettings,
    ConfirmTemplateApply,
    ManageUserAccess,
    StartedVacationFlow,
    AbsenseSettings,
    ConcludedVacationFlow,
  },

  data() {
    return {
      currentStep: 1,
      localSelectedUsers: [],
      selectedTemplate: null,
      settingsValues: null,
      customTemplate: null,
    };
  },

  computed: {
    ...mapGetters([
      "loadingSign",
      "allVacationTemplates",
      "usersWithVacationSettings",
      "users",
      "hasConectaSign",
    ]),

    settings: {
      get() {
        return this.settingsValues;
      },
      set(value) {
        value = value || {};
        this.settingsValues = { ...this.settingsValues, ...value };
      },
    },

    selectedUsesWithVacationSettings() {
      return this.localSelectedUsers.reduce((acc, user) => {
        if (this.usersWithVacationSettings.includes(user.email)) {
          acc.push(user);
        }
        return acc;
      }, []);
    },

    hasUsersWithVacation() {
      return this.selectedUsesWithVacationSettings.length > 0;
    },

    stepNames() {
      return [
        {
          titleKey: "common.intro",
          subtitleKey: "vacationApply.intro.subtitle",
          icon: "ph-users",
          id: 1,
        },
        {
          titleKey: "vacationApply.model.title",
          subtitleKey: "vacationApply.model.subtitle",
          icon: "ph-users",
          id: 2,
        },
        {
          titleKey: "vacationApply.configure.title",
          subtitleKey: "vacationApply.configure.subtitle",
          icon: "ph-fill ph-cards",
          id: 3,
        },
        {
          titleKey: "vacationApply.absenseSettings.stepTitle",
          subtitleKey: "vacationApply.absenseSettings.stepSubtitle",
          icon: "ph-fill ph-cards",
          id: 4,
        },
        {
          titleKey: "vacationApply.save.title",
          subtitleKey: "vacationApply.save.subtitle",
          icon: "ph-users",
          id: 5,
        },
        {
          titleKey: "vacationApply.manageAccess.title",
          subtitleKey: "vacationApply.manageAccess.subtitle",
          icon: "ph-users",
          id: 6,
        },
        {
          titleKey: "vacationApply.concludedVacationFlow.title",
          subtitleKey: "vacationApply.concludedVacationFlow.subtitle",
          icon: "ph-users",
          id: 7,
        },
      ];
    },

    autocompleteSelected: {
      get() {
        return this.localSelectedUsers.map((item) => item.email);
      },
      set(newValue) {
        this.localSelectedUsers = this.users.filter((item) =>
          newValue.includes(item.email)
        );
      },
    },

    selectedTemplateKey() {
      return this.selectedTemplate?.key || "";
    },

    selectedTemplateName() {
      return this.selectedTemplate?.name || "";
    },

    htmlTemplate() {
      if (this.selectedTemplate) {
        return this.selectedTemplate.content;
      } else if (this.customTemplate) {
        return this.customTemplate.response_body_html;
      }
      return "";
    },

    subjectTemplate() {
      if (this.selectedTemplate) {
        return this.selectedTemplate.response_subject;
      } else if (this.customTemplate) {
        return this.customTemplate.response_subject;
      }
      return "";
    },

    showHideDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },

    vacationSettings() {
      return {
        settings: {
          enable_auto_reply: this.settings.enable_auto_reply,
          start_time: this.settings.start_time,
          end_time: this.settings.end_time,
          response_subject: this.subjectTemplate,
          response_body_plain_text: null,
          restrict_to_contacts: this.settings.restrict_to_contacts,
          restrict_to_domain: this.settings.restrict_to_domain,
          response_body_html: this.htmlTemplate,
          create_calendar_event: this.settings.create_calendar_event,
          calendar_event_name: this.settings.calendar_event_name,
          forwarding_email_on_vacation:
            this.settings.forwarding_email_on_vacation,
        },
      };
    },

    isVacationFlow() {
      return this.$route.name === "VacationMessage";
    },
  },

  watch: {
    showHideDialog() {
      if (this.showHideDialog) {
        this.currentStep = 1;
      }
      if (this.$route.query.key) {
        this.selectTemplate(this.$route.query.key);
      }
    },

    "settings.start_time"() {
      if (this.settings.start_time) {
        this.setTemporaryVacationStartDate(this.settings.start_time);
      }
    },

    "settings.end_time"() {
      if (this.settings.end_time) {
        this.setTemporaryVacationEndDate(this.settings.end_time);
      }
    },

    selectedUsers: {
      handler() {
        this.localSelectedUsers = this.selectedUsers.slice();
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations([
      "setSnackBar",
      "setTemporaryVacationStartDate",
      "setTemporaryVacationEndDate",
      "setSignLoading",
    ]),
    ...mapActions(["updateVacationInBatch", "updateVacation"]),

    handleClose(reset = false) {
      if (this.isVacationFlow) {
        this.returnToPreviousPage();
      } else {
        this.$emit("reset", reset);
      }
    },

    returnToPreviousPage() {
      this.$router.go(-1);
    },

    async applyVacation(users) {
      if (this.hasConectaSign) {
        const payload = {
          vacation_template_key: this.selectedTemplateKey,
          emails: users,
          vacation: this.vacationSettings,
        };

        await this.updateVacationInBatch(payload)
          .then(() => (this.currentStep = 6))
          .catch(() => this.handleClose(true));

        return;
      }

      const payload = {
        email: users.pop(),
        settings: this.vacationSettings.settings,
      };

      this.setSignLoading(true);

      await this.updateVacation(payload)
        .then(() => {
          this.currentStep = 6;
          this.setSnackBar({
            show: true,
            message: successMessages.vacation_message,
          });
        })
        .catch(() => {
          this.setSnackBar({
            show: true,
            color: "error",
            message: errorMessages.vacation_message,
          });
        })
        .finally(() => {
          this.setSignLoading(false);
        });
    },

    /* Save vacation settings like init and end date, etc */
    setVacationSettings(vacation) {
      this.settings = vacation;
    },

    /* Used when it only has a html template that is not saved in custom or
      default templates (it's a new template)
    */
    setTemplate(template) {
      this.customTemplate = template;
      this.selectedTemplate = null;
      this.currentStep = 3;
    },

    /* Select a custom or default template to use */
    setSelectedTemplate(template) {
      this.selectTemplate(template.key);
      this.customTemplate = null;
      this.currentStep = 3;
    },

    /* Search and set a custom or default template to use */
    selectTemplate(key) {
      this.currentStep = 3;
      this.selectedTemplate = this.allVacationTemplates.find(
        (template) => template.key === key
      );

      if (this.selectedTemplate) {
        this.currentStep = 2;
      } else {
        this.setSnackBar({
          color: "error",
          show: true,
          message: errorMessages.select_template_to_apply,
        });
        setTimeout(() => this.setErrorRedirect(), 3000);
      }
    },

    setErrorRedirect() {
      this.$emit("close");
      this.$router.push("/sign/template");
    },

    finish() {
      this.handleClose();
      this.showHideDialog = false;
    },
  },

  async beforeMount() {
    if (this.$route.query.key) {
      await this.selectTemplate(this.$route.query.key);
    }
  },
};
</script>

<style>
v-toolbar__extension {
  padding: 0px !important;
}

.v-stepper__step__step,
.v-stepper__step__step.primary {
  background-color: var(--v-accent-base) !important;
  border-color: var(--v-accent-base) !important;
}
</style>
